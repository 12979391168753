.error-text {
  margin-top: 10px;
  color: $error;
  font-size: 12px;
  font-style: italic;
}

.error-box {
  padding: 15px 20px;
  background-color: $warning;
  border: 1px solid rgba($error, 0.1);
  border-radius: 6px;
  p {
    font-size: 14px;
    line-height: 20px;
    color: $error;
  }
}

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url("../../images/page-404-bg.png") no-repeat center center;
  &-content {
    text-align: center;
    img {
      margin: 0 auto 50px;
    }
    p {
      font-size: 24px;
      line-height: 1;
      margin-bottom: 100px;
    }
    .btn {
      text-decoration: none;
      max-width: 415px;
      width: 100%;
      height: 60px;
      line-height: 60px;
    }
  }
}
