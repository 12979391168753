.notificaton {
  position: fixed;
  top: 0;
  left: 130px;
  right: 30px;
  z-index: 999;
  padding: 20px;
  text-align: center;
  border-radius: 0 0 25px 25px;
  background: $gray;
  color: rgba($black, 0.5);
  &-close {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    opacity: 0.5;
    cursor: pointer;
    transition: opacity .3s ease-in-out;
    img {
      width: 16px;
      height: auto;
    }
    &:hover {
      opacity: 1;
    }
  }
  &.success {
    background: #D9FF8F;
    border-width: 1px, 1px, 1px, 0px;
    border-style: solid;
    border-color: #79D900;
    color: $dark;
  }
  &.error {
    background: #FFD7D4;
    border-width: 1px, 1px, 1px, 0px;
    border-style: solid;
    border-color: #FF1001;
    color: $dark;
  }
}