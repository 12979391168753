@font-face {
  font-family: 'SF Pro Text';
  src: url('../../fonts/SFProText-BoldItalic.eot');
  src: url('../../fonts/SFProText-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/SFProText-BoldItalic.woff2') format('woff2'),
      url('../../fonts/SFProText-BoldItalic.woff') format('woff'),
      url('../../fonts/SFProText-BoldItalic.ttf') format('truetype'),
      url('../../fonts/SFProText-BoldItalic.svg#SFProText-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../../fonts/SFProText-Bold.eot');
  src: url('../../fonts/SFProText-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/SFProText-Bold.woff2') format('woff2'),
      url('../../fonts/SFProText-Bold.woff') format('woff'),
      url('../../fonts/SFProText-Bold.ttf') format('truetype'),
      url('../../fonts/SFProText-Bold.svg#SFProText-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../../fonts/SFProText-Light.eot');
  src: url('../../fonts/SFProText-Light.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/SFProText-Light.woff2') format('woff2'),
      url('../../fonts/SFProText-Light.woff') format('woff'),
      url('../../fonts/SFProText-Light.ttf') format('truetype'),
      url('../../fonts/SFProText-Light.svg#SFProText-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../../fonts/SFProText-LightItalic.eot');
  src: url('../../fonts/SFProText-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/SFProText-LightItalic.woff2') format('woff2'),
      url('../../fonts/SFProText-LightItalic.woff') format('woff'),
      url('../../fonts/SFProText-LightItalic.ttf') format('truetype'),
      url('../../fonts/SFProText-LightItalic.svg#SFProText-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../../fonts/SFProText-MediumItalic.eot');
  src: url('../../fonts/SFProText-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/SFProText-MediumItalic.woff2') format('woff2'),
      url('../../fonts/SFProText-MediumItalic.woff') format('woff'),
      url('../../fonts/SFProText-MediumItalic.ttf') format('truetype'),
      url('../../fonts/SFProText-MediumItalic.svg#SFProText-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../../fonts/SFProText-Medium.eot');
  src: url('../../fonts/SFProText-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/SFProText-Medium.woff2') format('woff2'),
      url('../../fonts/SFProText-Medium.woff') format('woff'),
      url('../../fonts/SFProText-Medium.ttf') format('truetype'),
      url('../../fonts/SFProText-Medium.svg#SFProText-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../../fonts/SFProText-Regular.eot');
  src: url('../../fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/SFProText-Regular.woff2') format('woff2'),
      url('../../fonts/SFProText-Regular.woff') format('woff'),
      url('../../fonts/SFProText-Regular.ttf') format('truetype'),
      url('../../fonts/SFProText-Regular.svg#SFProText-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../../fonts/SFProText-RegularItalic.eot');
  src: url('../../fonts/SFProText-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/SFProText-RegularItalic.woff2') format('woff2'),
      url('../../fonts/SFProText-RegularItalic.woff') format('woff'),
      url('../../fonts/SFProText-RegularItalic.ttf') format('truetype'),
      url('../../fonts/SFProText-RegularItalic.svg#SFProText-RegularItalic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

// Colors
// ------
$white: #fff;
$black: #000;
$dark: #353535;
$gray: #CACFD5;
$gray-darkest: #444444;
$gray-light: #94979B;
$gray-lighter: #EBEDEF;
$orange: #F8B637;
$orange-darker: #FFA800;
$orange-darkest: #F09E00;
$orange-light: #F39950;
$error: #FF0F00;
$warning: #FFD7D4;
$rejected: #FF483D;
$accepted: #79D900;
$revoked: #5B5B5B;

// Font
// ----
$font-family: 'SF Pro Text';
$font-size: 16px;
$line-height: 1.2;

// Other
// -----