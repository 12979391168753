.consent-event-single {
  border-radius: 6px;
  border: 1px solid $gray;
  background: $white;
  box-shadow: 0px 2px 18px 0px rgba($black, 0.1);
  flex-grow: 1;
  &-header {
    display: flex;
    background: rgba($gray, 0.2);
    position: relative;
    overflow: hidden;
  }
  &-id,
  &-status,
  &-archived {
    padding: 24px 50px 24px 30px;
    p {
      font-size: 18px;
      line-height: 23px;
    }
  }
  &-id {
    background: rgba($gray, 0.25);
    span {
      margin-right: 55px;
      color: $orange-light;
    }
  }
  &-status {
    text-transform: uppercase;
    span {
      margin-left: 40px;
      text-transform: none;
      color: $orange-light;
    } 
  }
  &-archived {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    padding: 0;
    p {
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
      span {
        color: $gray;
      }
    }
  }
  &-body {
    padding: 38px 20px 25px;
    height: calc(100vh - 171px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-row {
    max-width: 1056px;
    margin: 0 auto;
    display: flex;
    gap: 30px;
  }
  &-col {
    &:first-of-type {
      width: 40%;
    }
    &:last-of-type {
      width: 60%;
    }
  }
  &-info {
    .form-field + .form-field {
      margin-top: 29px;
    }
    .form-field-row {
      .form-field + .form-field {
        margin-top: 0;
      }
    }
  }
  &.user-single {
    .consent-event-single-body {
      padding: 30px 20px 20px;
      height: calc(100vh - 161px);
    }
    .consent-event-single-col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .consent-event-single-info {
      margin-bottom: 35px;
    }
    .consent-event-single-id,
    .consent-event-single-status,
    .consent-event-single-archived {
      padding: 19px 50px 19px 30px;
    }
    .consent-event-single-id {
      max-width: 525px;
      width: 100%;
      span {
        margin-right: 30px;
      }
    }
  }
  &.settings {
    background: none;
    box-shadow: none;
    border: none;
    .consent-event-single-body {
      padding: 60px 20px 20px;
      height: auto;
    }
  }
}

.retrieval {
  padding: 10px 0 0;
  background: rgba(#FFD686, 0.15);
  border: 1px solid rgba(#FFD686, 0.75);
  border-radius: 12px;
  &-title {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
    p {
      font-size: 18px;
      line-height: 23px;
      text-transform: uppercase;
      color: $orange-light;
    }
    span {
      font-size: 14px;
      line-height: 20px;
      color: #5B5B5B;
    }
  }
  .input-field {
    .input {
      min-height: 40px;
    }
    &.input-field-large {
      .input {
        min-height: 76px;
      }
    }
  }
  &-row {
    display: flex;
    align-items: center;
    padding: 0 25px 0 40px;
    > .form-field:first-child {
      flex: 0 0 179px;
      label {
        padding-right: 30px;
      }
    }
    > .form-field:last-child {
      flex-grow: 1;
    }
    & + .retrieval-row {
      margin-top: 2px;
    }
    &-info {
      .form-field.input-field {
        margin-top: 20px;
        .input {
          min-height: auto;
        }
      }
      &.with-icon {
        .form-field.input-field {
          margin-top: 20px;
        }
      }
    }
    &-label {
      & + .retrieval-row-info {
        .form-field.input-field {
          margin-top: 10px;
        }
      }
    }
  }
  &-label {
    font-size: 14px;
    line-height: 20px;
    color: $orange-light;
    margin: 15px 0 8px;
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 50px;
  }
  &-request {
    span {
      display: inline-block;
      padding: 4px 15px;
      min-width: 115px;
      border-radius: 0 16px 16px 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-transform: uppercase;
      text-align: center;
      border: 1px solid transparent;
      &.retrieved {
        background: rgba(#ffd686, 0.3);
        color: #ff9422;
        border-color: #ffd686;
      }
      &.requested,
      &.retrieval-request {
        background: #ffc149;
      }
      &.confirmed {
        background: #AA80F9;
      }
      &.pending {
        background: rgba($orange-light, 0.5);
      }
      &.paid {
        background: #79D900;
      }
      &.rejected {
        background: rgba(#5b5b5b, 0.1);
        border-color: rgba(#5b5b5b, 0.2);
      }
    }
    & + .retrieval-row {
      margin-top: 10px;
    }
  }
  &-functions {
    padding: 25px 25px 45px 40px;
    margin-top: 15px;
    background: rgba(#FFD686, 0.15);
  }
}

.subscription {
  &-info {
    padding: 30px 20px 110px 30px;
    background: rgba(#FFD686, 0.15);
    border: 1px solid rgba(#FFD686, 0.75);
    border-radius: 12px;
    margin-bottom: 35px;
    .form-field-row {
      margin-top: 45px;
    }
    .form-field {
      &.input-field-secondary-light {
        .input {
          height: 50px;
          padding: 0 20px;
          border-radius: 12px;
          background: $white;
          border: 1px solid rgba(#FFD686, 0.5);
        }
      }
    }
  }
  &-label {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 10px 15px;
    background: #FFD686;
    border-radius: 6px;
    text-transform: uppercase;
    img {
      max-height: 20px;
      width: auto;
    }
    &.premium {
      color: #FF9422;
    }
  }
}

.user-events {
  padding: 31px 15px 33px;
  border: 1px solid $gray;
  border-radius: 12px;
  text-align: center;
  &-row {
    display: flex;
  }
  &-col {
    flex: 0 1 33.333333%;
    padding: 0 15px;
    span {
      display: block;
    }
    p {
      font-size: 40px;
      font-weight: 300;
      line-height: 1;
    }
    img {
      margin: 14px auto 12px;
    }
    & + .user-events-col {
      border-left: 1px solid $gray;
    }
  }
  &-link {
    margin-top: 43px;
    a {
      color: #FF9422;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.user-account {
  padding: 30px 0;
  border: 1px solid $gray;
  border-radius: 12px;
  .input {
    background: #EFF1F2;
    border: 12px;
    padding: 0 15px;
    height: 50px;
    span {
      color: rgba($black, 0.3);
    }
  }
  &-row {
    display: flex;
    margin: 0 -10px;
  }
  &-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 1 50%;
    padding: 45px 40px 43px;
    & + .user-account-col {
      border-left: 1px solid $gray;
    }
  }
  &-delete {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: 7px;
    span {
      font-size: 18px;
      line-height: 20px;
      color: #FF9422;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.remain-peaches {
  display: flex;
  align-items: flex-end;
  gap: 25px;
  margin-top: 40px;
  .form-field {
    width: 200px;
    flex-shrink: 0;
    .input {
      border: 1px solid $gray;
      background: #EFF1F2;
      &.empty {
        color: $gray;
      }
    }
  }
  > p {
    font-size: 14px;
    line-height: 20px;
    color: rgba($black, 0.3);
  }
}