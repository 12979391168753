* {
  box-sizing: border-box;
}

body {
  line-height: $line-height;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  font: {
    family: $font-family;
    size: $font-size;
    weight: 300;
  }
}