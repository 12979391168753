.form-field {
  .form-field + & {
    margin-top: 20px;
  }
  &-row {
    display: flex;
    gap: 24px;
    > .form-field {
      flex: 0 1 50%;
      margin: 0;
    }
    .form-field + & {
      margin-top: 20px;
    }
  }
}

.form-field-error {
  &.input-field {
    input {
      border-color: #FF1001;
    }
  }
}

.input-field {
  &.disabled {
    label {
      opacity: 0.5;
    }
  }
  label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
  input,
  .input {
    display: block;
    background: #EFF1F2;
    border: 1px solid $gray;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    height: 55px;
    border-radius: 8px;
    padding: 0 20px;
    outline: 0;
    &::placeholder {
      color: $gray;
    }
    &.center {
      text-align: center;
      justify-content: center;
    }
    &:focus {
      border: 1px solid #5B5B5B
    }
  }
  textarea {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid $gray;
    background-color: #EFF1F2;
    outline: 0;
    resize: none;
    &::placeholder {
      color: rgba($black, 0.25);
    }
  }
  .input {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    position: relative;
    min-height: 40px;
    height: auto;
    padding: 0;
    word-break: break-all;
    &.input-orange {
      color: #FF9422;
    }
    span {
      margin-left: 8px;
      color: rgba($black, 0.2);
    }
    u {
      text-decoration: none;
      margin-left: 8px;
    }
    a {
      color: $black;
      text-decoration: none;
      transition: color .3s ease-in-out;
      &:hover {
        color: $orange-light;
        img {
          filter: invert(74%) sepia(80%) saturate(1434%) hue-rotate(323deg) brightness(99%) contrast(92%);
        }
      }
    }
    &-event {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      box-shadow: 0px 0px 4px 0px #CACFD5;
      margin-left: 20px;
      background: $white;
      flex-shrink: 0;
      > * {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .retrieval-functions & {
        margin-left: 10px;
        .tooltip {
          &-content {
            max-width: 200px;
          }
        }
      }
      .event-copy {
        cursor: pointer;
        &:not(.disabled):hover {
          img {
            filter: invert(74%) sepia(80%) saturate(1434%) hue-rotate(323deg) brightness(99%) contrast(92%);
          }
        }
        &.disabled {
          opacity: 0.25;
          pointer-events: none;
        }
      }
      .tooltip {
        &-content {
          top: 48px;
          left: -1px;
        }
      }
      &:first-of-type {
        .tooltip {
          &-content {
            left: auto;
            right: -2px;
            &::after {
              left: auto;
              right: 17px;
            }
          }
        }
      }
    }
  }
  .password-field {
    position: relative;
    input {
      padding-right: 60px;
    }
    .show-password {
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  .sign-in & {
    input {
      width: 100%;
    }
  }
  &.input-field-secondary {
    label, .input {
      font-weight: 300;
    }
    input {
      display: block;
      width: 100%;
    }
    label {
      font-size: 18px;
      line-height: 23px;
      color: rgba(#353535, 0.5);
    }
    .retrieval & {
      input {
        border: none;
      }
    }
    .retrieval-row & {
      margin: 0;
      label {
        margin: 0;
      }
    }
    &.input-field-secondary-light {
      input {
        background: rgba($white, 0.75);
        border-color: transparent;
        color: rgba($black, 0.5);
        span {
          color: rgba($black, 0.2);
        }
      }
    }
    &.input-field-secondary-border {
      input {
        border: 1px solid rgba($black, 0.5);
        &:disabled {
          border: none;
          background: rgba($gray, 0.2);
          opacity: 0.5;
          cursor: default;
        }
      }
    }
  }
  &.input-field-large {
    .input {
      border: 1px solid rgba(#FFD686, 0.5);
      background: $white;
      padding: 20px;
      border-radius: 12px;
      justify-content: space-between;
      align-items: flex-start;
      color: rgba($dark, 0.5);
    }
  }
}

.form-submit {
  margin-top: 70px;
  button {
    width: 100%;
  }
  .auth-form & {
    margin-top: 25px;
    text-align: center;
  }
}

.checkbox {
  display: inline-block;
  height: 18px;
  width: 18px;
  font-size: 0;
  line-height: 0;
  &-field {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  input {
    position: absolute;
    margin: 0;
    opacity: 0;
    &:checked ~ .checkbox-checkmark {
      border-color: $gray-darkest;
      img {
        opacity: 1;
      }
    }
  }
  &-checkmark {
    display: inline-block;
    border: 1px solid $gray;
    background: rgba($gray, 0.3);
    border-radius: 4px;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    transition: border-color .3s ease-in-out;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity .3s ease-in-out;
    }
    &:hover {
      border-color: $gray-darkest;
    }
  }
}