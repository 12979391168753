.questions {
  max-width: 1056px;
  margin: 25px auto 0;
  padding: 30px 35px 30px 30px;
  border: 1px solid rgba(#FF9422, 0.5);
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    .questions-content {
      > p {
        color: darken(#FF9422, 10%);
      }
    }
  }
  &.active {
    .questions-content {
      > p {
        color: darken(#FF9422, 10%);
        img {
          rotate: -180deg;
        }
      }
    }
    .questions-list {
      max-height: 1000px;
      pointer-events: all;
    }
  }
  &-role {
    p {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 23px;
      font-weight: 300;
      color: rgba($dark, 0.5);
      span {
        color: $dark;
      }
      strong {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px 0 30px;
        width: 24px;
        height: 24px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        border-radius: 2px;
        border: 1px solid $gray;
        background: #EFF1F2;
        color: $dark;
        &.type-limits {
          color: #5b5b5b;
          background-color: rgba($gray, 0.5);
        }
      }
    }
  }
  &-content {
    > p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      line-height: 23px;
      font-weight: 300;
      color: #FF9422;
      text-transform: uppercase;
      transition: color .3s ease-in-out;
      img {
        margin-left: auto;
        transition: rotate .3s ease-in-out;
      }
      span {
        margin-left: 25px;
        color: $dark;
        text-transform: none;
      }
    }
  }
  &-list {
    max-height: 0;
    overflow-y: scroll;
    pointer-events: none;
    transition: max-height 0.3s ease-in-out;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    ol {
      margin: 15px 0 0;
      font-size: 14px;
      line-height: 20px;
      color: rgba(#4E4D4D, 0.75);
      padding-left: 20px;
      li {
        list-style: decimal;
      }
      li + li {
        margin-top: 10px;
      }
    }
    ul {
      margin: 30px 0 0;
      p {
        display: block;
        font-size: 18px;
        font-weight: 300;
        line-height: 23px;
        color: rgba(#353535, 0.5);
      }
      div + div {
        margin-top: 25px;
      }
      li {
        padding-bottom: 25px;
        border-bottom: 1px solid #FFC149;
        & + li {
          margin-top: 25px;
        }
        &:last-child {
          border: 0;
          padding-bottom: 0;
        }
      }
    }
    .history {
      &-row {
        display: flex;
        align-items: center;
        gap: 25px;
        > p:first-child {
          flex: 0 1 130px;
        }
      }
      &-date {
        > p {
          &:last-child {
            color: $dark;
            span {
              margin-left: 8px;
              color: rgba($black, 0.2);
            }
          }
        }
      }
      &-status {
        span {
          display: inline-block;
          padding: 4px 15px;
          min-width: 115px;
          border-radius: 0 16px 16px 0;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-transform: uppercase;
          text-align: center;
          border: 1px solid transparent;
          color: $dark;
          &.retrieved {
            background: rgba(#ffd686, 0.3);
            color: #ff9422;
            border-color: #ffd686;
          }
          &.requested,
          &.retrieval-request {
            background: #ffc149;
          }
          &.confirmed {
            background: #AA80F9;
          }
          &.pending {
            background: rgba($orange-light, 0.5);
          }
          &.paid {
            background: #79D900;
          }
          &.rejected {
            background: rgba(#5b5b5b, 0.1);
            border-color: rgba(#5b5b5b, 0.2);
          }
        }
      }
    }
  }
  &.role {
    border-color: $gray;
    cursor: default;
  }
}