.container {
  padding: 10px 30px 30px 20px;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 47px;
    margin-bottom: 10px;
  }
  &-title {
    display: flex;
    align-items: center;
    gap: 15px;
    p,
    span,
    a {
      font-size: 18px;
      line-height: 20px;
      color: $black;
    }
    > p {
      text-transform: uppercase;
      font-weight: 600;
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  &-content {
    > table {
      width: 100%;
    }
  }
  &.container-settings {
    background: url("../../images/settings-bg.png") no-repeat center bottom;
    min-height: 100vh;
  }
}

@media screen and (max-width: 991px) {
  .container {
    &-header {
      display: block;
    }
  }
}
