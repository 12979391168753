.settings {
  .form-field {
    input,
    .input {
      width: 100%;
    }
  }
  &-info {
    margin-bottom: 40px;
  }
  &-password {
    &-label {
      margin-bottom: 35px;
      p {
        font-size: 18px;
        line-height: 20px;
        color: #FF9422;
        text-transform: uppercase;
      }
    }
    &-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      margin-top: 70px;
      transition: opacity .3s ease-in-out;
    }
  }
  &-auth {
    padding: 30px 30px 48px;
    border: 1px solid rgba(#FFD686, 0.5);
    border-radius: 12px;
    &-cb {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 25px;
      p {
        font-size: 18px;
        line-height: 20px;
        color: #FF9422;
        text-transform: uppercase;
      }
    }
    &-content {
      margin-bottom: 24px;
      p {
        font-size: 18px;
        line-height: 25px;
      }
    }
    &-form {
      display: flex;
      gap: 20px;
      align-items: flex-end;
      transition: opacity .3s ease-in-out;
      .form-field {
        flex-grow: 1;
        position: relative;
        input {
          height: 50px;
          border: 1px solid rgba($black, 0.5);
        }
        .error-text {
          position: absolute;
          top: 100%;
        }
      }
      button {
        margin-bottom: 6px;
      }
    }
  }
  .error-box {
    margin-top: 30px;
  }
}