.consent-events {
  overflow-x: scroll;
  border: 1px solid #cacfd5;
  border-radius: 6px 6px 0 0;
  border-bottom: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  table {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    min-width: 1000px;
  }
  thead,
  tbody,
  tr {
    display: block;
  }
  thead {
    color: rgba($dark, 0.5);
    background: #eff1f2;
    tr {
      padding: 9px 60px 10px 30px;
    }
    th {
      span {
        cursor: pointer;
        img {
          display: inline;
          margin-left: 10px;
          vertical-align: middle;
        }
      }
      &:last-child {
        padding-left: 15px;
      }
    }
  }
  tbody {
    height: calc(100vh - 191px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    tr {
      min-height: 56px;
      padding: 0 65px 0 30px;
      position: relative;
      cursor: pointer;
      td {
        padding: 12px 0;
      }
      &::after {
        content: "";
        background-color: rgba($orange, 0.1);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }
      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }
  }
  tr {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba($gray, 0.5);
    td,
    th {
      display: inline-block;
      padding-right: 15px;
      width: calc(93% / 7);
      flex-shrink: 0;
      &:last-child {
        padding-right: 0;
      }
      &.consent-events-type {
        width: 7%;
      }
    }
    &.is-archived {
      background: rgba($gray, 0.15);
    }
  }
  &-date {
    span {
      display: inline-block;
      color: rgba(#2b2d42, 0.3);
    }
  }
  &-location {
    img {
      display: inline;
      vertical-align: middle;
      margin-right: 10px;
    }
    a {
      color: $black;
      text-decoration: underline;
      font-weight: 400;
      cursor: pointer;
      transition: color 0.3s ease-in-out;
      &:hover {
        color: $orange-light;
      }
    }
  }
  &-type {
    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 2px;
      background: #eff1f2;
      border: 1px solid $gray;
      font-weight: 500;
    }
    &.type-limits {
      span {
        color: #5b5b5b;
        background-color: rgba($gray, 0.5);
      }
    }
  }
  &-status {
    text-transform: uppercase;
    font-weight: 500;
    img {
      display: inline;
      margin-left: 10px;
    }
    span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 120px;
      padding: 5px 10px;
      border-radius: 26px;
      &.accepted {
        background-color: rgba($accepted, 0.1);
        color: $accepted;
      }
      &.denied {
        color: $rejected;
        background-color: rgba($rejected, 0.05);
      }
      &.revoked {
        color: $revoked;
        background-color: rgba($revoked, 0.1);
      }
    }
  }
  &-request {
    background: rgba(#ffd686, 0.15);
    min-height: 55px;
    span {
      display: inline-block;
      padding: 4px 15px;
      min-width: 115px;
      border-radius: 0 16px 16px 0;
      margin-left: 15px;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
      border: 1px solid transparent;
      &.retrieved {
        background: rgba(#ffd686, 0.3);
        color: #ff9422;
        border-color: #ffd686;
      }
      &.requested,
      &.retrieval-request {
        background: #ffc149;
      }
      &.confirmed {
        background: #AA80F9;
      }
      &.pending {
        background: rgba($orange-light, 0.5);
      }
      &.paid {
        background: #79D900;
      }
      &.rejected {
        background: rgba(#5b5b5b, 0.1);
        border-color: rgba(#5b5b5b, 0.2);
      }
    }
  }
  &-archived {
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
    z-index: 3;
    width: 30px !important;
    .tooltip {
      height: 31px;
    }
    &:hover {
      z-index: 4;
    }
  }
  .user-subscription {
    text-transform: uppercase;
    img {
      display: inline;
      vertical-align: bottom;
      margin-right: 10px;
    }
    &.premium {
      color: #ff9422;
    }
  }
  &.users {
    tr {
      th {
        &:last-child {
          padding-right: 15px;
        }
      }
      td,
      th {
        width: calc(93% / 6);
        &:first-child {
          width: 17%;
        }
        &:last-of-type {
          text-align: center;
        }
        &:nth-child(3) {
          padding-right: 30px;
          margin-right: 20px;
          text-align: center;
          img {
            display: inline-block;
          }
        }
        &:nth-last-child(2) {
          padding: 0 15px;
          text-align: center;
          img {
            display: inline-block;
          }
        }
        &:nth-last-child(3) {
          padding-left: 5px;
        }
      }
    }
    tbody tr td {
      padding: 16px 0 18px;
    }
  }
  &:not(.users) {
    thead {
      tr {
        padding: 9px 35px 10px 20px;
      }
    }
    tbody {
      tr {
        padding: 0 35px 0 20px;
        td {
          padding: 12px 0;
        }
      }
    }
    tr {
      td, th {
        width: 11.7%;
        &.consent-events-date {
          width: 13%;
        }
        &.consent-events-type {
          width: 5%;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .consent-events {
    thead {
      tr {
        padding: 9px 50px 10px 20px;
      }
    }
    tbody {
      tr {
        padding: 0 50px 0 20px;
      }
    }
    &-request {
      span {
        margin-left: 8px;
      }
    }
    &-archived {
      &::before {
        width: 20px;
        height: 20px;
        right: 7px;
      }
    }
  }
}

.consent-events.users tbody tr.no-results,
.consent-events tbody tr.no-results,
.container-content > table .no-results {
  height: 100%;
  border: 0;
  background: $white;
  padding: 0;
  cursor: default;
  &:hover {
    background: $white;
  }
  &::after {
    display: none;
  }
  td {
    width: 100%;
    padding: 75px 30px 50px;
    text-align: center;
    img {
      margin: 0 auto 50px;
    }
    h3 {
      font-size: 24px;
      font-weight: 400;
      line-height: 1;
      margin-bottom: 50px;
    }
    p {
      font-size: 18px;
      line-height: 26px;
      max-width: 330px;
      margin: 0 auto;
    }
  }
}
