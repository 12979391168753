.download {
  &.download-cee {
    .download {
      &-content {
        padding: 0 18px;
        max-width: 700px;
        max-height: calc(100vh - 285px);
        overflow-y: scroll;
        scrollbar-color: rgba(255, 214, 134, 0.3) white;
        scrollbar-width: thin;
      }
    }
  }
  &:not(.download-cee) {
    .download {
      &-content-wrap {
        height: 100vh;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }
  }
  &-container {
    display: flex;
    height: 100vh;
  }
  &-content-wrap {
    flex: 1;
    padding: 95px 20px 60px;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-sidebar {
    img {
      height: 100%;
      width: auto;
    }
  }
  &-content {
    margin: 0 auto;
    max-width: 675px;
    > p:first-of-type {
      margin-bottom: 20px;
    }
    p:not(.error-text) {
      font-size: 18px;
      line-height: 23px;
      &.warning,
      span {
        color: #F39950;
      }
      span {
        font-weight: 700;
      }
      a {
        color: #F39950;
        font-weight: 700;
        &:hover {
          text-decoration: none;
        }
      }
    }
    p + p {
      margin-top: 25px;
    }
    > img {
      margin: 80px auto;
    }
    ol {
      margin: 25px 0 0;
      padding-left: 20px;
      font-size: 18px;
      line-height: 23px;
      li {
        margin-top: 5px;
      }
      > li {
        list-style: decimal;
        ol {
          li {
            list-style: lower-alpha;
          }
        }
      }
      ol {
        margin: 0;
      }
    }
  }
  &-title {
    text-align: center;
    margin-bottom: 50px;
  }
  &-info {
    margin: 50px auto;
    max-width: 300px;
    p,
    p:not(.error-text) {
      color: $dark;
      font-weight: 400;
      span {
        display: block;
        margin-bottom: 15px;
        color: rgba($dark, 0.5);
        font-weight: 300;
      }
    }
    p + p {
      margin-top: 10px;
    }
  }
  .revoke-content {
    > p:first-child {
      font-weight: 400;
      color: #FF9422;
    }
    &-date {
      margin: 50px 0;
      p {
        color: $dark;
        &:first-child {
          margin-bottom: 30px;
          color: rgba($dark, 0.5);
        }
        span {
          margin-left: 15px;
          color: rgba($dark, 0.25);
        }
      }
    }
  }
  .revoke-button {
    margin-top: 100px;
    text-align: center;
    button {
      height: 60px;
      line-height: 60px;
      max-width: 415px;
      width: 100%;
    }
  }
  .revoke-link {
    margin-top: 50px;
    text-align: center;
    span {
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
      color: #F39950;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &.revoke-page {
    .download-title {
      margin-bottom: 120px;
    }
    .download-content {
      max-width: 550px;
    }
  }
}

.passphrase {
  p:not(.error-text) {
    margin-bottom: 18px;
    color: rgba($dark, 0.5);
  }
  &-row {
    display: flex;
    gap: 25px;
  }
  &-input {
    display: flex;
    align-items: center;
    flex: 0 1 33.333333%;
    gap: 8px;
    label {
      font-size: 16px;
      line-height: 20px;
      color: #F39950;
      width: 22px;
    }
    input {
      display: block;
      height: 42px;
      width: 100%;
      background: $white;
      border: 1px solid $gray;
      border-radius: 34px;
      outline: none;
      padding: 15px 0;
      text-align: center;
      transition: all .3s ease-in-out;
      &:disabled {
        border: 1px dashed $gray;
        background: #EFF1F2;
      }
      &:focus {
        border-color: #5B5B5B;
        box-shadow: 0px 2px 4px 0px #5B5B5B80 inset;
      }
      &.input-filled {
        border-color: #5B5B5B;
        background: #EFF1F2;
      }
    }
    & + .passphrase-input {
      margin-top: 10px;
    }
  }
  &-submit {
    margin-top: 50px;
    text-align: center;
    button {
      height: 60px;
      line-height: 60px;
      max-width: 415px;
      width: 100%;
    }
  }
  &-link {
    margin-top: 30px;
    text-align: center;
    a {
      color: #F39950;
      font-weight: 400;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .download {
    &-sidebar {
      max-width: 300px;
      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .download {
    &-content-wrap {
      padding: 40px 20px;
    }
    &-sidebar {
      display: none;
    }
    &-title {
      margin-bottom: 40px;
    }
    &-content {
      > img {
        margin: 40px auto;
        max-width: 100%;
        height: auto;
      }
    }
    &.revoke-page {
      .download-title {
        margin-bottom: 40px;
      }
    }
  }
  .passphrase {
    &-row {
      display: block;
    }
    &-col {
      & + .passphrase-col {
        margin-top: 10px;
      }
    }
    &-input {
      width: 100%;
    }
  }
}