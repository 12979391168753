.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 110px;
  height: 100vh;
  overflow-y: scroll;
  padding: 15px 10px;
  background: linear-gradient(181.45deg, rgba(255, 214, 134, 0.75) 31.64%, rgba(255, 193, 73, 0.75) 73.61%, rgba(255, 148, 34, 0.75) 97.92%);
  border-radius: 0px 25px 25px 0px;
  text-align: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: 0px 0px 11px 0px rgba($black, 0.3);
  &::-webkit-scrollbar {
    display: none;
  }
  a, button {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    color: $dark;
    span {
      display: block;
    }
    img {
      display: inline-block;
      margin-bottom: 5px;
    }
  }
  &-nav {
    a {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px 15px;
      border-radius: 14px;
      color: $black;
      transition: background-color .3s ease-in-out;
      min-height: 85px;
      &:hover {
        // background-color: rgba($gray, 0.4);
        background-color: rgba(#F8B637, 0.4);
      }
      &:active,
      &.active {
        background-color: $white;
        box-shadow: 0px 0px 2px 1px rgba($dark, 0.15);
      }
    }
  }
  &-logo {
    margin-bottom: 20px;
  }
  &-logout {
    padding-top: 12px;
    position: relative;
    button {
      color: $dark;
      padding: 0;
      margin: 0;
      border: 0;
      background: none;
      cursor: pointer;
      img {
        padding: 15px 19px 16px 20px;
        border-radius: 14px;
        background-color: transparent;
        border: 1px solid $dark;
        transition: background-color .3s ease-in-out;
      }
      &:hover {
        img {
          background-color: rgba(#F8B637, 0.4);
        }
      }
    }
    &::before {
      content: '';
      width: 30px;
      height: 1px;
      background: rgba($gray, 0.5);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}