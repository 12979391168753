.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  max-width: 750px;
  width: 100%;
  background: $white;
  box-shadow: 0px 0px 50px 0px rgba($dark, 0.5);
  border: 1px solid rgba(#FF9422, 0.5);
  border-radius: 6px;
  text-align: center;
  &.modal-expired {
    z-index: 1000;
  }
  &-content {
    padding: 35px 30px 40px;
    background: url('../../images/modal-bg.png') no-repeat left top;
  }
  img {
    margin: 0 auto 50px;
  }
  h3 {
    text-transform: uppercase;
    margin-bottom: 50px;
  }
  p {
    font-size: 18px;
    line-height: 1;
    margin: 20px 0;
    strong {
      font-weight: 500;
    }
  }
  &-buttons {
    margin-top: 35px;
    button {
      margin: 9px;
    }
  }
}