.auth-checkbox {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  .switch {
    display: inline-block;
    height: 24px;
    width: 42px;
    position: relative;
    input {
      display:none;
    }
  }
  .slider {
    background-color: rgba($gray, 0.5);
    border-radius: 34px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    cursor: pointer;
    transition: .4s;
    &:before {
      content: "";
      height: 18px;
      width: 18px;
      background-color: $white;
      border-radius: 50%;
      position: absolute;
      bottom: 3px;
      left: 3px;
      transition: .4s;
    }
    &::after {
      content: url('../../images/icon-cb-mark.svg');
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
      opacity: 0;
      transition: .4s;
    }
  }
  input:checked + .slider {
    background-color: $orange-light;
    &:before {
      transform: translateX(18px);
    }
    &::after {
      opacity: 1;
      right: 7px;
    }
  }
}