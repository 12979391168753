/*
 * Reset Stylesheet
 * Created by Quantum Web LLC
 * Contact: vladan@quantumwebdev.com
*/

@import "base/config";
@import "utils/all";

html {
	color: $black;
	background: $white;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
	@extend %reset-space;
}


article,
aside,
audio,
canvas,
command,
datalist,
details,
embed,
figcaption,
figure,
footer,
header,
hgroup,
keygen,
meter,
nav,
output,
progress,
section,
source,
video{
	@extend %reset-space;
	display: block;
}

mark,
rp,
rt,
ruby,
summary,
time{
	@extend %reset-space;
	display: inline;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

fieldset,
img {
	border: 0;
}

address,
caption,
cite,
code,
dfn,
strong,
em,
th,
var {
	font-style: normal;
	font-weight: normal;
}

li {
	list-style: none;
}

caption,
th {
	text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;
	font-weight: normal;
}

q:before,
q:after {
	content:'';
}

abbr,
acronym {
	border: 0;
	font-variant: normal;
}

sup {
	vertical-align: text-top;
}

sub {
	vertical-align: text-bottom;
}

input,
textarea,
select {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
}

legend {
	color: #000;
}

.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

a,
a:active {
	outline: none;
}

img {
  display: block;
}

strong {
	font-weight: bold;
}

em {
	font-style: italic;
}

strong em,
em strong {
	font-weight: bold;
	font-style: italic;
}

@import "base/typography";