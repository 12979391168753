.note {
  position: relative;
  textarea {
    height: 217px;
  }
  &-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      box-shadow: 0px 0px 4px 0px $gray;
      border: 1px solid $gray;
      cursor: pointer;
      &:hover {
        img {
          filter: invert(74%) sepia(80%) saturate(1434%) hue-rotate(323deg) brightness(99%) contrast(92%);
        }
      }
    }
  }
}