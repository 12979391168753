.auth-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  max-width: 475px;
  width: 100%;
  background: $white;
  border-radius: 12px;
  padding: 30px 30px 50px;
  &-close {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 28px;
    line-height: 1;
    cursor: pointer;
    transition: color .3s ease-in-out;
    &:hover {
      color: #f8b637;
    }
  }
  > img {
    display: block;
    max-width: 100%;
    height: auto;
    margin:  0 auto 30px;
  }
  h2 {
    margin: 50px 0 45px;
    font-weight: 300;
    text-align: center;
  }
  input {
    width: 100%;
  }
  .btn {
    height: 60px;
  }
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($dark, 0.6);
    z-index: 998;
  }
}