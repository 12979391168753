.search {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: rgba($gray, 0.2);
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0px 0px 4px 0px #CACFD5;
  border: 1px solid transparent;
  input, button {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: none;
  }
  input {
    width: 0;
    flex-grow: 1;
    font-size: 18px;
    line-height: 20px;
    transition: width .3s ease-in-out, padding 0.3s ease-in-out;
    &::placeholder {
      color: $gray;
    }
  }
  button {
    cursor: pointer;
    padding: 12px 13px 12px 13px;
    border-radius: 50%;
    background-color: $white;
    transition: background-color .3s ease-in-out;
    &:hover {
      background-color: rgba($white, 0.6);
    }
  }
  &.search-active {
    border: 1px solid $gray;
    input {
      width: 427px;
      padding: 0 15px 0 30px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .search {
    &.search-active {
      input {
        width: 327px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .search {
    display: flex;
    margin-top: 25px;
    input {
      width: 100%;
      padding: 0 15px;
    }
  }
}