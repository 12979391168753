.tooltip {
  display: inline-block;
  position: relative;
  &-content {
    padding: 10px 20px;
    font-size: 14px;
    line-height: 18px;
    color: #EFF1F2;
    background: $dark;
    border-radius: 4px;
    width: max-content;
    max-width: 150px;
    position: absolute;
    top: calc(100% + 5px);
    left: -14px;
    z-index: 9;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 20px;
      margin-left: -4px;
      border-width: 4px;
      border-style: solid;
      border-color: transparent transparent $dark transparent;
    }
  }
  &:hover {
    .tooltip-content {
      opacity: 1;
      pointer-events: all;
    }
  }
  &.left {
    .tooltip-content {
      left: auto;
      right: -14px;
      &::after {
        left: auto;
        right: 20px;
      }
    }
  }
  &.disabled {
    pointer-events: none;
  }
}