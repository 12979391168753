.sign-in {
  padding: 20px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../images/sign-in-bg.png') no-repeat center center / cover;
  &-box {
    max-width: 475px;
    width: 100%;
    background: $white;
    border-radius: 12px;
    padding: 35px 30px 30px;
    > img {
      display: block;
      max-width: 100%;
      height: auto;
      margin:  0 auto 30px;
    }
    h2 {
      text-align: center;
      margin: 60px 0 30px;
    }
  }
  .form-field + .form-field {
    margin-top: 30px;
    &.checkbox-field {
      margin-top: 10px;
    }
  }
  .error-box {
    margin-top: 35px;
  }
}

.sign-in .error-box + h2 {
  margin-top: 35px;
}