.passphrase-log {
  padding: 30px 35px 30px 30px;
  border: 1px solid rgba(#FFC149, 0.5);
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    .passphrase-log-content {
      > p {
        color: darken(#FF9422, 10%);
      }
    }
  }
  &.active {
    .passphrase-log-content {
      > p {
        color: darken(#FF9422, 10%);
        img {
          rotate: -180deg;
        }
      }
    }
    .passphrase-log-list {
      max-height: 1000px;
      pointer-events: all;
    }
  }
  &-wrap {
    max-width: 1056px;
    margin: 35px auto 0;
    > p {
      margin: 0 0 10px;
      font-size: 18px;
      line-height: 23px;
      font-weight: 300;
      color: rgba($dark, 0.5);
    }
    &.single {
      .passphrase-log {
        cursor: default;
      }
      .passphrase-log-arrow {
        opacity: 0.25;
      }
    }
  }
  &-content {
    > p {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 23px;
      font-weight: 300;
      color: #FF9422;
      transition: color .3s ease-in-out;
      img {
        margin-left: 20px;
        transition: rotate .3s ease-in-out;
      }
      span {
        color: $dark;
        text-transform: none;
        u {
          text-decoration: none;
          color: rgba($black, 0.4);
          margin-left: 10px;
        }
      }
    }
  }
  &-date {
    margin-left: 85px;
    > span:not(.passphrase-log-email) {
      margin: 0 50px 0 60px;
    }
  }
  &-arrow {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  &-list {
    max-height: 0;
    overflow-y: scroll;
    pointer-events: none;
    transition: max-height 0.3s ease-in-out;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    > p {
      margin: 35px 0 0;
      padding: 35px 0 0;
      border-top: 1px solid #FFC149;
      font-size: 18px;
      line-height: 23px;
      font-weight: 300;
      color: rgba($dark, 0.5);
    }
    ul {
      margin: 35px 0 0;
      li {
        padding-bottom: 25px;
        border-bottom: 1px solid rgba(#5B5B5B, 0.5);
        font-size: 18px;
        font-weight: 300;
        line-height: 23px;
        color: $dark;
        > span {
          margin-left: 50px;
          > span:not(.passphrase-log-email) {
            margin: 0 50px;
          }
        }
        & + li {
          margin-top: 25px;
        }
        &:last-child {
          border: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}