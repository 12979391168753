.pagination {
  display: flex;
  align-items: center;
  padding: 12px 20px 14px;
  border: 1px solid rgba($gray, 0.5);
  border-radius: 0 0 6px 6px;
  &-btn {
    background: none;
    border: 0;
    outline: 0;
    padding: 0;
    cursor: pointer;
    img {
      max-height: 14px;
      width: auto;
      &:hover {
        filter: invert(64%) sepia(94%) saturate(1723%) hue-rotate(342deg) brightness(102%) contrast(101%);
      }
    }
    &-disabled {
      opacity: 0.15;
      cursor: default;
      img {
        &:hover {
          filter: none;
        }
      }
    }
  }
  &-prev {
    img {
      rotate: 180deg;
    }
  }
  ul {
    margin: 0 10px;
    li {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
      border-radius: 2px;
      font-size: 14px;
      line-height: 20px;
      transition: color .3s ease-in-out;
      &:hover {
        color: #FF9422;
      }
      &.pagination-current {
        cursor: default;
        color: #FF9422;
        background-color: rgba(#FFD686, 0.5);
      }
    }
  }
}