h2 {
  font-size: 40px;
  line-height: 55px;
}

h3 {
  font-size: 20px;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 32px;
    line-height: 42px;
  }
}