.auth {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;
  margin: 50px 0 25px;
  border-bottom: 1px solid rgba($black, 0.1);
  p {
    font-size: 18px;
    line-height: 23px;
  }
  &-form {
    .btn-link {
      margin-top: 20px;
    }
  }
}