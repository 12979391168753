.btn {
  display: inline-block;
  height: 40px;
  padding: 0 34px;
  font-size: 20px;
  font-weight: 300;
  line-height: 38px;
  border-radius: 12px;
  text-align: center;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  &.btn-small {
    font-size: 18px;
  }
  &.btn-primary {
    border: 0;
    background: linear-gradient(181.46deg, #FFD686 4.69%, #FF9422 108.35%);
    box-shadow: 0px 0px 4px 0px #CACFD5;
    color: $black;
    transition: background-color .3s ease-in-out;
    position: relative;
    span {
      position: relative;
      z-index: 2;
    }
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 12px;
      background: linear-gradient(181.46deg, #ffc757 4.69%, #ff890b 108.35%);
      z-index: 1;
      opacity: 0;
      transition: opacity 0.25s linear;
    }
    &:hover,
    &:focus,
    &:active {
      &::before {
        opacity: 1;
      }
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
      &::before {
        opacity: 0;
      }
    }
  }
  &.btn-secondary {
    border: 1px solid $gray-darkest;
    background-color: $gray-darkest;
    color: $white;
    transition: background-color .3s ease-in-out, border-color .3s ease-in-out;
    &:hover {
      background-color: $black;
      border-color: $black;
    }
    &:disabled {
      opacity: 0.5;
      border: 1px solid $gray-darkest;
      background-color: $gray-darkest;
      cursor: default;
    }
  }
  &.btn-outline {
    padding: 0 30px;
    border: 1px solid #FF9422;
    background-color: rgba(#FFD686, 0.25);
    color: #FF9422;
    transition: background-color .3s ease-in-out, color .3s ease-in-out;
    &:hover {
      background-color: #FFD686;
      color: $dark;
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
      &:hover {
        border: 1px solid #FF9422;
        background-color: rgba(#FFD686, 0.25);
        color: #FF9422;
      }
    }
  }
  &-link {
    display: inline-block;
    border: none;
    outline: none;
    background: none;
    padding: 0;
    margin: 0;
    font-size: 18px;
    line-height: 20px;
    text-decoration: underline;
    color: $orange-light;
    padding: 0 33px;
    cursor: pointer;
    transition: color .3s ease-in-out;
    &:hover {
      color: $orange;
    }
  }
  &-large {
    height: 60px;
    line-height: 60px;
  }
}